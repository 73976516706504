import "./styles/index.scss";
import "aos/dist/aos.css";
import "@fortawesome/fontawesome-free/css/solid.css";
import "@fortawesome/fontawesome-free/js/fontawesome";
import "@fortawesome/fontawesome-free/js/solid";
import "noto-sans-jp/noto_sans_jp_regular/css.css";
import "noto-sans-jp/noto_sans_jp_medium/css.css";
import "noto-sans-jp/noto_sans_jp_bold/css.css";
import "./assets/i/kaitak_FV_20210917.png";
import "./assets/i/kaitak_FV_20210730.png";
import "./assets/i/Kaitak_FV_200813seizou.png";
import "./assets/i/support_agent.png";
import "./assets/i/text_snippet.png";
import "./assets/i/fact_check.png";
import "./assets/i/group_add.png";
import "./assets/i/add_call.png";
import "./assets/i/kaitak_image_support_01.png";
import "./assets/i/kaitak_image_support_02.png";
import "./assets/i/Kaitak_LP_Photo-min.png";
import "./assets/i/imejinlogo.png";
import "./assets/i/kaitak-ogp-220123.jpg";
import "./assets/i/kaitak-ogp-lp6-220123.jpg";
import "./assets/i/kaitak-ogp.jpg";
import "./assets/i/kaitak_220504/kaitak-ogp-lp6-220623.jpg";
import "./assets/i/kaitak_220504/kaitak-ogp-lp7-220623.jpg";
import "./assets/i/img_multichannel_01.png";
import "./assets/i/img_multichannel_02.png";
import "./assets/i/img_multichannel_03.png";
import "./assets/i/img_Kaitak_pattern.png";
import "./assets/i/img_senzoku_03.png";
import "./assets/i/img_senzoku_04.png";

import $ from "jquery";
window.$ = $;
import AOS from "aos";
import LazyLoad from "vanilla-lazyload";

var breakPoint = 991;

const lazyLoadInstance = new LazyLoad({
  elements_selector: ".lazy",
  threshold: 300,
});

// Align Height
function alignHeight(target) {
      var windowWidth = $(window).width();
      var heightMax = 0;
      target.css('min-height', 'auto');

      if(windowWidth > breakPoint) {
        target.each(function(){ 
        var heightThis = $(this).outerHeight(); // カラムの高さ取得
        if ( heightThis > heightMax ) { heightMax = heightThis; }    // 最大値を判定・更新
        });
        target.css('min-height', heightMax+'px'); // それに合わせる
      };
  }

// Scroll.js
$(window).on("popstate", function (e) {
  e.preventDefault();
  var target = window.location.href.split("#")[1];
  if (
    target != "" &&
    target != undefined &&
    document.getElementById(target) != null
  ) {
    $("html, body")
      .stop()
      .animate(
        { scrollTop: $("#" + target).offset().top },
        500,
        "swing",
        function () {
          window.location.hash = target;
        }
      );
  }
});

$(document).ready(function () {
  // SF_scripts();
  if (typeof AOS !== "undefined" && $("body").hasClass("SFG_body") === false) {
    AOS.init({
      easing: "ease-out-cubic",
      offset: 50,
      disable: "mobile",
    });
    setTimeout(function () {
      if ($(".slick-initialized").length > 0) {
        AOS.refreshHard();
      }
    }, 200);
  }
  // Opening tabs

  function openTab(tab) {
    if (tab.hasClass("opened")) {
      $(".tab_text").animate({ height: 0 }, 300);
      tab.removeClass("opened");
    } else {
      var nextTabHeight = tab.next().find("div").outerHeight(true);
      $(".tab_text").not(tab.next()).animate({ height: 0 }, 300);
      tab.next().animate({ height: nextTabHeight }, 300);
      $(".tab_opener").removeClass("opened");
      tab.addClass("opened");
    }
  }

  $(".tab_opener").click(function () {
    openTab($(this));
  });

  if ($(".opening_tabs").length > 0) {
    $(".tab_opener").each(function () {
      if ($(this).hasClass("opened")) {
        $(this).removeClass("opened").trigger("click");
      }
    });
  }

  // hun menu
  $("#js-buttonHamburger").click(function () {
    $("body").toggleClass("is-drawerActive");
    $(this).attr("aria-expanded", $(this).attr("aria-expanded") == "false");
  });
  $(".nav-toggle").click(function () {
    $(this).toggleClass("js-active");

    if ($(this).hasClass("js-active")) {
      $(".nav-toggle-menu").addClass("js-active");
    } else {
      $(".nav-toggle-menu").removeClass("js-active");
    }
  });
  $(".nav-toggle-list-item").click(function () {
    $(".nav-toggle").attr("aria-expanded", false);
    $(".nav-toggle-menu").removeClass("js-active");
    $(".nav-toggle").removeClass("js-active");
  });

  //pricing
  var basicPrice = 9;
  var standardPrice = 12;
  var proPrice = 15;
  var month = 6;

  var basicPriceTarget = ".price-basic_headline .price_num";
  var standardPriceTarget = ".price-standard_headline .price_num";
  var proPriceTarget = ".price-pro_headline .price_num";
  var termTarget = ".price_term";

  function countDecimal(target, originalNum, completeNum) {
    $({ count: originalNum * 10 }).animate(
      { count: completeNum * 10 },
      {
        duration: 500,
        easing: "linear",
        progress: function () {
          $(target).text(Math.ceil(this.count) / 10);
        },
      }
    );
  }

  function countWithoutDecimal(target, originalNum, completeNum) {
    $({ count: originalNum }).animate(
      { count: completeNum },
      {
        duration: 500,
        easing: "linear",
        progress: function () {
          $(target).text(Math.ceil(this.count));
        },
      }
    );
    month = completeNum;
  }

  function putPrice(basic, standard, pro) {
    countDecimal(basicPriceTarget, basicPrice, basic);
    basicPrice = basic;
    countDecimal(standardPriceTarget, standardPrice, standard);
    standardPrice = standard;
    countDecimal(proPriceTarget, proPrice, pro);
    proPrice = pro;
  }

  $(window).on("load resize", function () {
        alignHeight($('.plan_item_desc'));
  });

  $("#pricing .radio_list input").change(function () {
    //put value of checked radio
    var radioval = $(this).val();

    if (radioval == "3month") {
      putPrice(18, 24, 36);
      $(".price_term").text("12");
    } else if (radioval == "6month") {
      putPrice(17, 23, 35);
      $(".price_term").text("18");
    } else if (radioval == "12month") {
      putPrice(16, 22, 34);
      $(".price_term").text("24");
    }
  });

  $("#pricing .radio_list_lp6 input").change(function () {
    //put value of checked radio
    var radioval = $(this).val();

    if (radioval == "3month") {
      putPrice(15, 18, 24);
      $(".price_term").text("6");
    } else if (radioval == "6month") {
      putPrice(14, 17, 23);
      $(".price_term").text("9");
    } else if (radioval == "12month") {
      putPrice(13, 16, 22);
      $(".price_term").text("12");
    }
  });

  $("#pricing .radio_list_lp8 input").change(function () {
    //put value of checked radio
    var radioval = $(this).val();

    if (radioval == "3month") {
      putPrice(15, 18, 24);
      $(".price_term").text("12");
    } else if (radioval == "6month") {
      putPrice(14, 17, 23);
      $(".price_term").text("18");
    } else if (radioval == "12month") {
      putPrice(13, 16, 22);
      $(".price_term").text("24");
    }
  });

  lazyLoadInstance.update();
});
